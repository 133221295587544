import Link from 'next/link';
import Leaders from '../leaders/Leaders';
import styles from './LeadersListing.module.scss';

/* eslint-disable-next-line */
interface PersonCard {
	__typename: string;
	name: string;
	personName: string;
	designation: string;
	slug: string;
	image: {
		__typename: string;
		image: {
			__typename: string;
			url: string;
			height: number;
			width: number;
			title: string;
		};
	};
}

interface LeadersListingProps {
	data: PersonCard[];
}

export function LeadersListing(props: LeadersListingProps) {
	const details = props.data;

	return (
		<section
			className={styles['leaders__listing']}
			data-aos="fade-up"
			data-aos-delay="360"
		>
			<div className="container container--max">
				<ul>
					{details.map((details, index) => {
						return (
							<li data-aos="fade-up" key={index}>
								<Link href={`leadership/${details.slug}`}>
									<Leaders data={details} />
								</Link>
							</li>
						);
					})}
				</ul>
			</div>
			<hr />
		</section>
	);
}

export default LeadersListing;

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { GET_MINI_BANNER_RICHTEXT_ITEMS } from '../../queries';
import { ApiContext } from '../../types/apiContext';
import { ButtonProps } from '../../types/type';
import { CalendlyButton } from '../CalendlyButton/CalendlyButton';
import StepperButton from '../stepper-button/StepperButton';
import Button from '../utility/button/button';
import styles from './mini-cta-banner.module.scss';

interface TextContent {
	json: any;
}

export interface MiniCtaBannerProps {
	name: string;
	buttonCollection: {
		items: ButtonProps[];
	};
	text: TextContent;
	containButton: boolean;
	containsStartAQuoteButton: boolean;
	quoteButtonText: string;
	containsCalendlyButton?: boolean;
	calendlyButtonText?: string;
}

const MiniCtaBanner: React.FC<MiniCtaBannerProps> = ({
	buttonCollection,
	text,
	containButton,
	containsStartAQuoteButton,
	containsCalendlyButton,
	quoteButtonText,
	calendlyButtonText
}: MiniCtaBannerProps) => {

	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node: any, children: React.ReactNode) => {
				return <>{children}</>;
			},
		},
	};

	const textData = text?.json as Document;

	return (
		<section className={styles.miniCtaBanner}>
			{containButton || containsStartAQuoteButton || containsCalendlyButton ? (
				<div className={styles.bannerContainer} data-aos="fade-up">
					<p data-aos="fade-up">
						{documentToReactComponents(textData, options)}
					</p>
					<div className={styles.buttonContainer}>
						{
							buttonCollection?.items &&
								buttonCollection?.items.map((button, index) => (
									<Button
										title={button.title}
										href={button.buttonUrl}
										textColor={button.textColour}
										backgroundColor={button.backgroundColour}
										borderColor={button.borderColour}
										key={index}
										type={button.type}
										openInNewTab={button.openInNewTab ?? false}
									/>
								))
						}
						{containsStartAQuoteButton && (
							<StepperButton title={quoteButtonText} />
						)}
						{containsCalendlyButton && (
							<CalendlyButton title={calendlyButtonText} />
						)}
					</div>
				</div>
			) : (
				<div className={styles.onlyTextContainer} data-aos="fade-up">
					{textData && (
						<p data-aos="fade-up">
							{documentToReactComponents(textData, options)}
						</p>
					)}
				</div>
			)}
		</section>
	);
};

export const resolveProps = async (props: { name }, apiContext: ApiContext) => {
		const { data } = await apiContext.contentful.query<{
		buttonRichTextSectionCollection: { items: MiniCtaBannerProps[] }
	}>(
		{
			query: GET_MINI_BANNER_RICHTEXT_ITEMS,
			variables: {
				component: props.name,
			},
		}
	);
	return 	{ ...props, ...data?.buttonRichTextSectionCollection?.items[0] };
}

export default MiniCtaBanner;

import { Image } from '@bw/elements';
import styles from './Leaders.module.scss';


/* eslint-disable-next-line */
interface Asset {
	__typename: string;
	url: string;
	width: number;
	height: number;
	title: string;
}

interface LogoBox {
	__typename: string;
	image: Asset;
}

export interface PersonCard {
	__typename: string;
	name: string;
	personName: string;
	designation: string;
	slug: string;
	image: LogoBox;
}
interface LeadersProps {
	data: PersonCard;
}

export function Leaders(props: LeadersProps) {
	const personDetails = props.data;
	const personImage = personDetails.image.image;

	return (
		<div className={styles['leaders']}>
			<figure
				className="aspect-box"
				style={{ '--aspect-ratio': '328/400' } as React.CSSProperties}
			>
				<Image
					src={personImage.url}
					alt={personImage.title}
					width={400}
					height={328}
				/>
			</figure>
			<article>
				<h2>{personDetails.name}</h2>
				<p>{personDetails.designation}</p>
			</article>
		</div>
	);
}

export default Leaders;

// 'use client';
import styles from './LeadershipDetail.module.scss';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

/* eslint-disable-next-line */
interface Asset {
	__typename: string;
	url: string;
	width: number;
	height: number;
	title: string;
}

interface LogoBox {
	__typename: string;
	image: Asset;
}

interface PersonCardDetailsContent {
	data: any;
	content: {
		data: any;
		marks: any[];
		value: string;
		nodeType: string;
	}[];
	nodeType: string;
}

interface PersonCardDetailsJson {
	data: any;
	content: PersonCardDetailsContent[];
	nodeType: string;
}

interface PersonCardDetails {
	__typename: string;
	json: PersonCardDetailsJson;
}

interface PersonCard {
	__typename: string;
	name: string;
	personName: string;
	designation: string;
	personTagline: string;
	details: PersonCardDetails;
	slug: string;
	image: LogoBox;
}

interface LeadershipDetailProps {
	data: {
		__typename: string;
		items: PersonCard[];
	};
}

export function LeadershipDetail(props: LeadershipDetailProps) {
	const personName = props.data.items[0].name;
	const firstName = personName.split(' ')[0];
	const personDetails = props.data.items[0].details.json as Document;

	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node: any, children: React.ReactNode) => {
				return <p>{children}</p>;
			},
		},
	};

	return (
		<div
			className={styles['leadership__detail']}
			data-aos="fade-up"
			data-aos-delay="400"
		>
			<div className="container">
				<h2 data-aos="fade-up">About {firstName}</h2>
				<div className="col_content" data-aos="fade-up" data-aos-delay="100">
					{documentToReactComponents(personDetails, options)}
				</div>
			</div>
		</div>
	);
}

export default LeadershipDetail;

import { Image } from '@bw/elements';
import styles from './LeadershipHero.module.scss';

/* eslint-disable-next-line */
interface Asset {
	__typename: string;
	url: string;
	width: number;
	height: number;
	title: string;
}

interface LogoBox {
	__typename: string;
	image: Asset;
}

interface PersonCard {
	__typename: string;
	name: string;
	personName: string;
	designation: string;
	slug: string;
	image: LogoBox;
}

interface LogoBannerWithPersonCardsPersonCardCollection {
	__typename: string;
	items: PersonCard[];
}

interface ButtonRichTextSection {
	__typename: string;
	name: string;
	// Add other properties if necessary
}

interface LogoBannerWithPersonCards {
	__typename: string;
	name: string;
	headingTagline: string;
	heading: string;
	description: string;
	logoImage: LogoBox;
	personCardCollection: LogoBannerWithPersonCardsPersonCardCollection;
	textWithButtonsComponent: ButtonRichTextSection;
	componentName: string;
}

interface LeadershipHeroProps {
	data: LogoBannerWithPersonCards;
}

export function LeadershipHero(props: LeadershipHeroProps) {
	const componentData = props.data;
	const image = componentData.logoImage.image;

	return (
		<section className={styles['leadership__hero']}>
			<div className="container container--max">
				<div className="container">
					<div className="hero__content">
						<h4 data-aos="fade-up">{componentData.headingTagline}</h4>
						<h1 data-aos="fade-up" data-aos-delay="100">
							{componentData.heading}
						</h1>
						<p data-aos="fade-up" data-aos-delay="300">
							{componentData.description}
						</p>
					</div>
				</div>
				<div className="hero__logo-layer">
					<Image
						data-aos="fade-left"
						data-aos-delay="200"
						src={image.url}
						alt={image.title}
						width={image.width}
						height={image.height}
					/>
				</div>
			</div>
		</section>
	);
}

export default LeadershipHero;

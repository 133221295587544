import { Image } from '@bw/elements';
import styles from './SingleLeaderHero.module.scss';


/* eslint-disable-next-line */
interface Asset {
	__typename: string;
	url: string;
	height: number;
	width: number;
	title: string;
}

interface LogoBox {
	__typename: string;
	image: Asset;
}

interface PersonCardDetails {
	__typename: string;
	json: any;
}

interface PersonCard {
	__typename: string;
	name: string;
	personName: string;
	designation: string;
	personTagline: string;
	details: PersonCardDetails;
	slug: string;
	image: LogoBox;
}

interface PersonCardCollectionData {
	__typename: string;
	items: PersonCard[];
}

export interface SingleLeaderHeroProps {
	data: PersonCardCollectionData;
}

export function SingleLeaderHero(props: SingleLeaderHeroProps) {
	const data = props.data.items[0];
	const image = data.image.image;

	return (
		<section className={styles['single__leader__hero']} data-aos="fade-up">
			<div className="container container--max">
				<div className="row">
					<figure
						className="aspect-box"
						style={{ '--aspect-ratio': '445/600' } as React.CSSProperties}
						data-aos="fade-up"
					>
						<Image
							src={image.url}
							alt={image.title}
							width={image.width}
							height={image.height}
						/>
					</figure>
					<article>
						<h4 data-aos="fade-up" data-aos-delay="100">
							{data.personTagline}
						</h4>
						<h1 data-aos="fade-up" data-aos-delay="200">
							{data.name}
						</h1>
						<p data-aos="fade-up" data-aos-delay="300">
							{data.designation}
						</p>
					</article>
				</div>
			</div>
		</section>
	);
}

export default SingleLeaderHero;
